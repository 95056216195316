import React from 'react'
import './About.css'
import AboutAnimate from './AboutAnimate'
// import MyPic from './MyPic'
import styled, { keyframes } from 'styled-components'
import {fadeInLeft} from 'react-animations'
import {fadeInRight} from 'react-animations'
import {flash} from 'react-animations'
import {bounceInLeft} from 'react-animations'
import Typical from 'react-typical'
import { 
    Link,
} from 'react-router-dom'


const FadeIn = styled.div`animation: 2s ${keyframes`${fadeInLeft}`}`
const FadeInR = styled.div`animation: 2s ${keyframes`${fadeInRight}`}`
const BounceInLeft = styled.div`animation: ${props => props.delay}s ${keyframes`${bounceInLeft}`}`
const Flash = styled.div`animation: 4s ${keyframes`${flash}`} infinite`

function getLinkElement(text, delay){
    return <BounceInLeft delay={delay}>
    <div className='about-intro-navigation-link' href="">{text}</div>
    </BounceInLeft>
}

function About(){
    return(
        <div className="about-container">
            <main className="about-text">
                <div className="about-intro">
                    <div className="about-intro-text-wrapper">
                            {/* <FadeIn className='intro-address'> */}
                                <p className='underConstructionMsg'>We are building this website to give users best experience on all devices. See you soon with new looks</p>
                            {/* </FadeIn> */}
                            <FadeIn className='intro-typical'>
                                <Typical
                                    className ='typical'
                                    loop={Infinity}
                                    wrapper='b'
                                    steps ={[
                                        'Uplift your business with latest technology', 1000,
                                        'Optimize cost by implementing efficient solutions', 1000,
                                        'Mordanise the infrastructure with help of IOT', 1000,
                                        'Automate existing workflows with the help of AI', 1000,
                                    ]}>    
                                </Typical>
                            </FadeIn>
                            {/* <FadeIn>
                                <Flash className='intro-instruction'>
                                    <p className='intro-instruction-text'>Click below to know more</p>
                                    <i className="far fa-arrow-alt-circle-down intro-arrow"></i>
                                </Flash>
                            </FadeIn>   */}
                        </div>
                    <div className="about-intro-navigation">
                        {/* <Link className="router-link" to="/services">{getLinkElement('Services', 1.5)}</Link> */}
                        <Link className="router-link" to="/contact">{getLinkElement('Get in touch', 2.50)}</Link>
                    </div>
                </div>
            </main>
            <FadeInR>
            <aside className='about-animation-container'>
                {/* <MyPic /> */}
                  <AboutAnimate />  
            </aside>
            </FadeInR>
        </div>
    )
}
export default About