import React from 'react';
import './ExpGA.css';
import styled, { keyframes } from 'styled-components'
// import {bounceInLeft} from 'react-animations'
import {bounceInRight} from 'react-animations'

// const BounceInLeft = styled.div`animation: ${props => props.delay}s ${keyframes`${bounceInLeft}`}`
const BounceInRight = styled.div`animation: ${props => props.delay}s ${keyframes`${bounceInRight}`}`


export default function ExpSE(props) {
    return(
        <BounceInRight className="experience-description-wrapper" delay={2}>
            <div >
                {/* <p className='experience-text'>Motivate and led a team of Retail Assistants, including training and on job mentoring.</p>
                <p className='experience-text'>Maintained excellent store standards, cleanliness and functionality of store equipment. 
                <p className='experience-text'>Carried workforce planning and rostering based on store and staff requirements.</p></p>
                <p className='experience-text'>Ensured a safe working environment at all times by following policies and procedures.</p> 
                <p className='experience-text'>Enjoyed merchandising the store, updating and improving store  layout</p> */}
                <p className='experience-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra justo in commodo molestie. Nunc dignissim ac ante a laoreet. Sed eros felis, mattis a mauris sed, volutpat aliquam eros. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec sed maximus leo. Aliquam ultrices metus nec semper fringilla. Maecenas placerat est id congue mattis. Nullam auctor lacinia pharetra. Curabitur ante nibh, faucibus a suscipit eget, ultricies in lorem. Etiam dapibus porta nibh non vehicula. Maecenas vitae molestie eros.</p>  
            </div>     
        </BounceInRight> 
    ) 
}