import React from 'react'
import './App.css'
// import MyPic from './MyPic'
import Header from "./Header"
import About from "./About"
import Services from "./Services"
import Portfolio from "./Portfolio"
import Footer from "./Footer"
import Development from "./Development"
import Testing from "./Testing"
import Innovate from "./Innovate"


import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom'
import ContactForm from './ContactForm'

class App extends React.Component{
  state = {
    target: ""
  }
  render(){
    return (
      <main className="main-application-wrapper">
        <BrowserRouter>
            <Header />
            <Switch>
              <Route path="/" exact>
                <About />
              </Route>
              <Route path="/services">
                <Services />
              </Route>
              <Route path="/portfolio">
                <Portfolio />
              </Route>
              <Route path="/development">
                <Development />
              </Route>
              <Route path="/innovate">
                <Innovate />
              </Route>
              <Route path="/testing">
                <Testing />
              </Route>
              <Route path="/contact">
                <ContactForm />
              </Route>
              <Route path="/:target">
                <About />
              </Route>
              <Route path="/">
                <About />
              </Route>
            </Switch>
            <Footer />
        </BrowserRouter>
      </main>
    )
  }
}
export default App